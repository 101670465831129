<template>
  <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.85597 20.5103C0.75551 20.5103 0.255302 19.7099 0.75551 18.7429L9.65922 1.23556C10.1594 0.268487 10.9598 0.268487 11.46 1.23556L20.3637 18.7429C20.8639 19.7099 20.3637 20.5103 19.2632 20.5103H1.85597Z"
    />
    <path
      d="M9.15889 7.70486L10.0926 13.8741C10.1927 14.4744 10.9596 14.4744 11.0597 13.8741L11.9601 7.70486C12.1268 5.30386 8.99216 5.30386 9.15889 7.70486Z"
      fill="white"
    />
    <path
      d="M10.5595 17.7756C11.333 17.7756 11.9601 17.1485 11.9601 16.375C11.9601 15.6015 11.333 14.9744 10.5595 14.9744C9.786 14.9744 9.15894 15.6015 9.15894 16.375C9.15894 17.1485 9.786 17.7756 10.5595 17.7756Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
